<template>
  <div>
    <el-row :gutter="10" class="rs_tj">
      <el-col :span="6" v-for="(item,index) in data.rs_tj" :key="index">
        <el-card>
          <div class="title">{{item.name}}</div>
          <el-divider></el-divider>
          <div class="content">
            <div class="count">{{item.count}}</div>
<!--            <div class="count">{{item.count | numFormat}}</div>-->
            <div class="desc">{{item.desc}}</div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="10" class="sy_tj">
      <el-col :span="6" v-for="(item,index) in data.sy_tj" :key="index">
        <el-card>
          <div>
            <div class="sy_tj_name">{{item.name}}</div>
<!--            <div class="sy_tj_count mb20">{{item.count}}</div>-->
            <div class="sy_tj_count mb20">{{item.count | digit}}</div>
          </div>
          <div>
            <div class="sy_tj_name">{{item.subtext}}</div>
            <div class="sy_tj_count">{{item.subcount}}</div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="16" class="monitoring_echarts">
        <el-card>
          <div>
            <div class="tabs">
              <div
                class="items"
                v-for="(item, index) in tabs"
                :key="index"
                :style="index == active ? 'color:#333333' : 'color:#989898'"
                @click="change(index)"
              >
                {{ item }}
              </div>
            </div>
            <el-divider></el-divider>
          </div>
          <div class="dt-tab" v-if="showMapTab">
            <div :class="['ta',!showMap?'active':'']" @click="dtTab(false)">区域数据</div>
            <div :class="['ta',showMap?'active':'']" @click="dtTab(true)">热力图</div>
          </div>

          <div class="content">
            <div class="maps" ref="map" @contextmenu.prevent='mapRight'></div>
          </div>
          
          <div :class="['contents',showMap?'active':'']">
            <!-- <div class="dtmap">这里是高德地图</div> -->
            <div id="container" class="dtmap"></div>
          </div>

        </el-card>
      </el-col>
      <el-col :span="8" class="monitoring_text">
        <el-card>
          <div class="title">各地{{tabs[active]}}{{active==3?'人数':''}}</div>
          <el-divider></el-divider>
          <div class="content">
            <div class="items"  v-for="(items,indexx) in city_list" :key="indexx">
              <div>
                <span style="margin-right:15px">{{indexx+1}}</span>
                <span>{{items.name}}</span>
              </div>
              <div>{{items.value | digits}}</div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
let that;
import chinaJson from '@/common/js/map/china1.json'
import city from '@/common/js/map/city.js'
import province from '@/common/js/map/province.js'
import axios from 'axios'
import AMapLoader from '@amap/amap-jsapi-loader';
export default {
  data() {
    let that=this
    return {
      showMapTab: false,
      showMap: false,
      data: {
        sy_tj:[],
        rs_tj:[]
      },
      city_list:[],
//    tabs: ["注册", "活跃", "消费", "服务人员分布","渠道推广员分布","经纪人分布","投票"],
      tabs: ["全部","消费", "服务人员分布","渠道推广员分布","经纪人分布"],
      active: 0,
      myChart:null,
      map_level:'china',
      provinceName:'',
      option: {
        title:{
          text:'地域图'
        },
        visualMap: {
          min: 0,
          max: 1000,
          text: ['高', '低'],
          calculable: true,
          inRange: {
              color: ['#DCECFF', '#2E9AFF']
          },
        },
        tooltip: {
          show: 'true',
          trigger: "item",
          formatter:function(params){
            let str=''
            if(that.active==0){
              for(let i =1;i<that.tabs.length;i++){
              	if(params.data){
              		str+=`<p>${params.name}${that.tabs[i]}人数——${params.data.value_list[i-1] || 0}人</p>`
              	}else{
              		str+=`<p>${params.name}${that.tabs[i]}人数——0人</p>`
              	}
              }
            }else{
              str+=`<p>${params.name}${that.tabs[that.active]}人数——${params.value || 0}人</p>`
            }
            return str
          },
          textStyle:{
            align:'left'
          }
        },
        geo: {
          map: "china",
          layoutCenter:['50%','50%'],
          layoutSize:'100%',
          label: {
              normal: {
                  show: false,
              },
              emphasis: {
                  show: false
              }
          },
          regions: [
            {
              name: "南海诸岛",
              itemStyle: {
                // 隐藏地图
                normal: {
                  opacity: 0, // 为 0 时不绘制该图形
                }
              },
              label: {
                show: false // 隐藏文字
              }
          }
          ],
          itemStyle: {
            normal: {
              borderColor: "#333333",
              borderWidth: "0.3",
              areaColor: "#FAFAFA",
            },
            emphasis: {
              areaColor: '#FFCF4A',
            }
          },
        },
        series: [
          {
            name:'用户数',
            type: "map",
            map: "china",
            geoIndex: 0, // 解决设置geo后地图重影问题
            data: []
          }
        ]
      },
      heatmapDataList: [],
      AMapObjct: null,
      mapObjct: null,
      xzq_bh: 0
    };
  },
  created() {
  	that = this;
  	if(!this.$store.state.userInfo) {
			// 防止未登录
			this.$router.push({
				path: '/login'
			})
		} else {
			this.init_data();
		}
  },
  mounted() {
    this.init_map()

    var that = this;
    AMapLoader.load({
        "key": "adf230809f69736483255b91cc2cd672",              // 申请好的Web端开发者Key，首次调用 load 时必填
        "version": "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        "plugins": [],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    }).then((AMap)=>{
        that.AMapObjct = AMap;
        that.mapObjct = new AMap.Map("container", {
            resizeEnable: true,
            center: [116.418261, 39.921984],
            zoom: 11
        });

    }).catch(e => {
        console.log(e);
    })
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      this.myChart.resize();
    });
  },
  methods: {
  	init_data(){
  		var url = 'statistic/monitorTjData';
			let params = {};
			this.fd_post(url, params).then((res) => {
				if(res.status) {
					that.data = res.detail;
				} else {
					that.$message.error(res.msg);
				}
			}).catch((err) => {
				that.$message.error('网络错误');
			});
  	},
    change(i){
      this.active = i;
      this.init_map();
    },
    //初始化
    init_map(){
    	var url = 'statistic/init_map';
			let params = {
				data_type: this.active+1,
				map_level: this.map_level,
        xzq_bh: this.xzq_bh
			};
			this.fd_post(url, params).then((res) => {
        this.city_list = res;
				this.option.series[0].data = res;
				this.myChart=this.$echarts.init(this.$refs.map)
	      this.$echarts.registerMap('china',chinaJson)
	      this.myChart.setOption(this.option,true)
	      this.myChart.on('click',(e)=>{
	        if(e.componentSubType=='map'){
	          this.getDownData(e.name)
	        }
	      })
	      //宽度自适应
	      window.addEventListener('resize',()=>{
	        this.myChart.resize()
	      })
			}).catch((err) => {
				this.$message.error('网络错误');
			});
    },
    //鼠标右击返回上一级
    mapRight(){
      console.log(this.map_level);
      if (this.map_level === 'china') return
      if (this.map_level === 'city' || this.map_level === null) {
        if (this.map_level === 'city') {
          this.showMapTab = false;
        }
        this.map_level='province';
        this.createMap(this.getProvinceId(this.provinceName),this.provinceName)
      } else if (this.map_level === 'province') {
        this.xzq_bh = 0
        this.map_level='china'
        this.$echarts.registerMap('china',chinaJson)
        this.option.series[0].map = 'china'
        this.option.geo.map = 'china';
        var url = 'statistic/init_map';
				let params = {
					data_type: this.active+1,
					map_level: this.map_level,
          xzq_bh: this.xzq_bh
				};
				this.fd_post(url, params).then((res) => {
					this.city_list = res;
					this.option.series[0].data = res;
					this.myChart.setOption(this.option,true)
				}).catch((err) => {
					this.$message.error('网络错误');
				});
        this.showMapTab = false;
      }
    },
    getDownData (name) {//点击下钻
      this.map_level=this.map_level==='china'?'province':this.map_level==='province'?'city':null //设置当前地图省地图/市地图
      console.log(this.map_level);
      if(!this.map_level) return //当前地图为市地图return出去
      if(this.map_level==='province'){
        this.createMap(this.getProvinceId(name),name);
        this.showMapTab = false;
      }else if(this.map_level==='city'){
        this.showMapTab = false;
        // this.createMap(this.getCityId(name),name);
        // //显示热力图切换按钮，并获取热力图数据
        // this.showMapTab = true;
        // this.initHeatMapData(name,this.getCityId(name));
      } else {
        this.showMapTab = false;
      }
    },
    async createMap(id,name){
    	console.log(id);
      this.xzq_bh = id;
      // 获取到下钻的json数据
      let newMapJson = await this.getMapJson(id);
      // 注册新下钻数据的map
      this.$echarts.registerMap(name, newMapJson);
      var url = 'statistic/init_map';
			let params = {
				data_type: this.active+1,
				map_level: this.map_level,
				xzq_bh: id
			};
			this.fd_post(url, params).then((res) => {
        console.log(res);
				this.city_list = res;
				// 把option中map设置为最新下钻的map名称
	      this.option.series[0].map = name;
	      this.option.geo.map = name;
	      this.option.series[0].data = res;
	      // 设置更改后的配置项
	      this.myChart.setOption(this.option,true)
			}).catch((err) => {
				this.$message.error('网络错误');
			});
    },
    //发起请求获取json数据
    async getMapJson (id) {
      let PorC=this.map_level==='province'?'geometryProvince':'geometryCouties'
      var formData = new FormData();
      let datas
      await axios({
        is_config:false,
        url:`https://img.jiasc.com/muzu/map/${PorC}/${id}.json`,
        data: formData,
      }).then(res=>{
        datas=res.data
      })
      return datas
    },
    //获取省id
    getProvinceId(name){
      for(let i in province){
        if(province[i].name===name){
          this.provinceName=name
          return province[i].id
        }
      }
    },
    //获取市id
    getCityId(name){
      for(let i in city){
        if(i===name){
          return city[i]
        }
      }
    },
    dtTab(show) {
      console.log(show)
      this.showMap=show;
    },
    initHeatMapData(name,id){
        console.log(name,id);
        // if (!isSupportCanvas()) {
        //     alert('热力图仅对支持canvas的浏览器适用,您所使用的浏览器不能使用热力图功能,请换个浏览器试试~')
        // }

        //详细的参数,可以查看heatmap.js的文档 http://www.patrick-wied.at/static/heatmapjs/docs.html
        //参数说明如下:
        /* visible 热力图是否显示,默认为true
         * opacity 热力图的透明度,分别对应heatmap.js的minOpacity和maxOpacity
         * radius 势力图的每个点的半径大小
         * gradient  {JSON} 热力图的渐变区间 . gradient如下所示
         *  {
         .2:'rgb(0, 255, 255)',
         .5:'rgb(0, 110, 255)',
         .8:'rgb(100, 0, 255)'
         }
         其中 key 表示插值的位置, 0-1
         value 为颜色值
         */

        var that = this;
        var heatmap;
        var url = 'statistic/init_map_heat';
        let params = {
          id: id,
          name: name,
        };
        this.fd_post(url, params).then((res) => {
          console.log(res);
          var position = [res.pos.lng, res.pos.lat];
          that.mapObjct.setCenter(position); 
          that.heatmapDataList = res.heat_list;
          //热力图数据
          that.mapObjct.plugin(["AMap.HeatMap"], function () {
              //初始化heatmap对象
              heatmap = new that.AMapObjct.HeatMap(that.mapObjct, {
                  radius: 25, //给定半径
                  opacity: [0, 0.9]
              });
              //设置数据集：该数据为北京部分“公园”数据
              heatmap.setDataSet({
                  data: that.heatmapDataList,
                  max: 100
              });
          });



          //https://img.jiasc.com/source/yxl/picture/marker.png
          // 以 icon URL 的形式创建一个途经点
          // var viaMarker = new AMap.Marker({
          //     position: new AMap.LngLat(116.38,39.92),
          //     // 将一张图片的地址设置为 icon
          //     icon: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-via-marker.png',
          //     // 设置了 icon 以后，设置 icon 的偏移量，以 icon 的 [center bottom] 为原点
          //     offset: new AMap.Pixel(-13, -30)
          // });

          // 创建一个 Icon
          var jishiIcon = new that.AMapObjct.Icon({
              // 图标尺寸
              size: new that.AMapObjct.Size(25, 34),
              // 图标的取图地址
              image: 'https://img.jiasc.com/source/yxl/picture/marker.png',
              // 图标所用图片大小
              imageSize: new that.AMapObjct.Size(135, 40),
              // 图标取图偏移量
              imageOffset: new that.AMapObjct.Pixel(-9, -3)
          });
          var mendianIcon = new that.AMapObjct.Icon({
              size: new that.AMapObjct.Size(25, 34),
              image: 'https://img.jiasc.com/source/yxl/picture/marker.png',
              imageSize: new that.AMapObjct.Size(135, 40),
              imageOffset: new that.AMapObjct.Pixel(-51, -3)
          });
          // 创建一个 icon
          var dailiIcon = new that.AMapObjct.Icon({
              size: new that.AMapObjct.Size(25, 34),
              image: 'https://img.jiasc.com/source/yxl/picture/marker.png',
              imageSize: new that.AMapObjct.Size(135, 40),
              imageOffset: new that.AMapObjct.Pixel(-95, -3)
          });


          var markers = [];
          for (var i = 0; i < res.js_list.length; i++) {
            var marker = new that.AMapObjct.Marker({
                position: new that.AMapObjct.LngLat(res.js_list[i].lng,res.js_list[i].lat),
                icon: jishiIcon,
                offset: new that.AMapObjct.Pixel(-13, -30)
            });
            markers.push(marker);
          }
          for (var i = 0; i < res.md_list.length; i++) {
            var marker = new that.AMapObjct.Marker({
                position: new that.AMapObjct.LngLat(res.md_list[i].lng,res.md_list[i].lat),
                icon: mendianIcon,
                offset: new that.AMapObjct.Pixel(-13, -30)
            });
            markers.push(marker);
          }
          for (var i = 0; i < res.dl_list.length; i++) {
            var marker = new that.AMapObjct.Marker({
                position: new that.AMapObjct.LngLat(res.dl_list[i].lng,res.dl_list[i].lat),
                icon: dailiIcon,
                offset: new that.AMapObjct.Pixel(-13, -30)
            });
            markers.push(marker);
          }

   

          // 将 icon 传入 marker
          // var endMarker = new AMap.Marker({
          //     position: new AMap.LngLat(116.45,39.93),
          //     icon: endIcon,
          //     offset: new AMap.Pixel(-13, -30)
          // });

          // 将 markers 添加到地图
          that.mapObjct.add(markers);




        }).catch((err) => {
          this.$message.error('网络错误');
        });

        
        
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./Data_monitor.scss";
</style>